@charset "UTF-8";
input[type="text"],
input[type="search"],
.form-text,
textarea,
select,
button.ui-multiselect {
  border: 1px solid #d8d9da;
  background-color: #fff;
  padding: 15px 20px;
  outline: 0;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px !important;
  vertical-align: top;
  border-radius: 0;
  width: 100%;
  box-sizing: border-box; }
  input[type="text"]:hover,
  input[type="search"]:hover,
  .form-text:hover,
  textarea:hover,
  select:hover,
  button.ui-multiselect:hover {
    border-color: #636466; }
  input[type="text"]:active, input[type="text"]:focus,
  input[type="search"]:active,
  input[type="search"]:focus,
  .form-text:active,
  .form-text:focus,
  textarea:active,
  textarea:focus,
  select:active,
  select:focus,
  button.ui-multiselect:active,
  button.ui-multiselect:focus {
    border-color: #636466; }

input[type="text"],
input.form-text,
input[type="search"],
select {
  padding-top: 0;
  padding-bottom: 0;
  height: 48px;
  -webkit-appearance: none; }
  input[type="text"]:disabled,
  input.form-text:disabled,
  input[type="search"]:disabled,
  select:disabled {
    opacity: .5; }

.form-submit,
button:not(.ui-multiselect) {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #626366;
  background-color: #626366;
  color: #fff;
  padding: 15px 20px;
  border-radius: 0;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  .form-submit:hover,
  button:not(.ui-multiselect):hover {
    border-color: #6e7073;
    background-color: #6e7073;
    color: #fff; }
  .form-submit:active, .form-submit:focus,
  button:not(.ui-multiselect):active,
  button:not(.ui-multiselect):focus {
    border-color: #565659;
    background-color: #565659;
    color: #fff; }
  .form-submit:hover,
  button:not(.ui-multiselect):hover {
    text-decoration: none; }

fieldset {
  border: 1px solid #e5e6e6 !important;
  background: #fff;
  padding-left: 15px;
  padding-right: 15px; }
  fieldset .fieldset-wrapper {
    padding-top: 0;
    padding-bottom: 0; }
  fieldset legend {
    border-radius: 0;
    background: transparent;
    color: #626366;
    font-weight: 700;
    display: block;
    padding: 5px;
    font-size: 1.15em;
    margin-left: -5px; }
    fieldset legend a,
    fieldset legend a:hover,
    fieldset legend a:active,
    fieldset legend a:focus {
      color: #626366;
      text-decoration: none; }
  fieldset.collapsible .fieldset-title::after {
    font-family: "iconfont";
    content: "";
    font-size: 12px;
    margin-left: 8px; }
  fieldset.collapsed .fieldset-title::after {
    content: ""; }
  fieldset fieldset {
    border: 1px solid #e5e6e6;
    margin-bottom: 25px; }
    fieldset fieldset:not(.collapsed) {
      box-shadow: 0 1px 0 #fff, 0 1px 0 #fff inset; }
    fieldset fieldset legend {
      font-size: 1em; }

.lt-ie9 input[type="text"],
.lt-ie9 input.form-text {
  max-width: none; }

*:focus {
  outline: 0; }

.progress .bar {
  border: 1px solid #d8d9da;
  background: #fff; }
  .progress .bar .filled {
    background-color: #626366; }

.form-textarea-wrapper::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden; }

.form-textarea-wrapper textarea {
  resize: none;
  width: 100%;
  max-width: 100%; }

.resizable-textarea .grippie {
  background: none repeat scroll 0 0 #e5e6e6;
  opacity: .5;
  border: 0 none;
  border-radius: 5px;
  float: right;
  height: 30px;
  margin: -35px 30px 0 0;
  position: relative;
  width: 30px;
  z-index: 5; }
  .resizable-textarea .grippie:hover {
    opacity: .0; }
  .resizable-textarea .grippie::after {
    bottom: 3px;
    content: "";
    font-family: "iconfont";
    font-size: 18px;
    position: absolute;
    right: 9px; }

html.js input.form-autocomplete {
  background-position: right 15px top 18px; }

.password-parent,
.confirm-parent {
  margin: 0; }

button.ui-multiselect {
  width: 95% !important;
  position: relative; }
  button.ui-multiselect.ui-state-active {
    border-color: #626366; }
  button.ui-multiselect .ui-icon {
    position: absolute;
    right: 20px;
    top: 50%; }
  button.ui-multiselect .ui-icon-triangle-1-s {
    content: " ";
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: #626366 transparent transparent; }

.ui-multiselect-menu {
  width: auto;
  background: #fff;
  color: #626366;
  padding: 20px;
  box-shadow: 5px 7px 1px -2px rgba(0, 0, 0, 0.4);
  min-width: 235px;
  z-index: 9999;
  position: absolute;
  top: 100%;
  left: 0;
  display: block !important;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  min-width: 280px;
  transition-property: max-height, padding;
  transition-duration: 350ms; }
  .ui-multiselect-menu[style*="top"] {
    max-height: 500px;
    padding-top: 15px;
    padding-bottom: 15px; }
  .ui-multiselect-menu[style*="none"] {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0; }

.ui-multiselect-checkboxes {
  margin: 10px 0 0;
  padding: 0 2px 0 0;
  overflow: auto; }
  .ui-multiselect-checkboxes label {
    cursor: pointer;
    display: block;
    line-height: 1.1em;
    border-bottom: 1px solid #e5e6e6;
    padding: 8px 4px 8px 28px; }
    .ui-multiselect-checkboxes label:hover {
      color: #fff;
      border-color: #626366;
      background-color: #626366; }
    .ui-multiselect-checkboxes label input {
      margin-left: -23px;
      float: left;
      margin-top: 2px;
      outline: 2px solid #e5e6e6; }
      .ui-multiselect-checkboxes label input[aria-selected="true"] {
        outline: 2px solid #bcbdbf; }
  .ui-multiselect-checkboxes li:last-child label {
    border-bottom: 0 none; }

.ui-multiselect-filter {
  margin-bottom: 5px;
  height: 40px;
  text-indent: -9000px; }
  .ui-multiselect-filter input {
    width: 100% !important;
    height: 40px;
    padding: 0 5px;
    font-size: 12px;
    float: left; }

.ui-multiselect-header .ui-helper-reset {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between; }
  .ui-multiselect-header .ui-helper-reset a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    background-color: transparent;
    border-color: #626366;
    color: #626366;
    padding: 8px 10px;
    border-radius: 0;
    line-height: 1em;
    font-size: 12px;
    line-height: 1.05em;
    font-size: 12px; }
    .ui-multiselect-header .ui-helper-reset a:hover {
      background-color: transparent;
      border-color: #76777b;
      color: #626366; }
    .ui-multiselect-header .ui-helper-reset a:active, .ui-multiselect-header .ui-helper-reset a:focus {
      background-color: transparent;
      border-color: #565659;
      color: #626366; }
    .ui-multiselect-header .ui-helper-reset a:hover {
      text-decoration: none; }
  .ui-multiselect-header .ui-helper-reset .ui-multiselect-close {
    display: none; }

#ui-datepicker-div {
  border-right: 0 none;
  border-bottom: 0 none;
  background: #fff;
  color: #626366;
  padding: 20px;
  box-shadow: 5px 7px 1px -2px rgba(0, 0, 0, 0.4);
  min-width: 235px;
  z-index: 9999;
  position: absolute;
  top: 100%;
  left: 0;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif; }
  #ui-datepicker-div .ui-datepicker-title {
    line-height: 1.1em;
    margin: 0; }
    #ui-datepicker-div .ui-datepicker-title select {
      padding-left: 3px;
      padding-right: 3px; }
  #ui-datepicker-div .ui-datepicker-header > a {
    height: 30px;
    width: 30px;
    top: 0;
    line-height: 30px;
    border-radius: 50%;
    background: #5b5b5e;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    text-align: center; }
    #ui-datepicker-div .ui-datepicker-header > a .ui-icon {
      display: none; }
    #ui-datepicker-div .ui-datepicker-header > a::before {
      font-family: "iconfont";
      vertical-align: middle;
      text-align: center;
      width: 100%; }
    #ui-datepicker-div .ui-datepicker-header > a.ui-datepicker-prev::before {
      content: ""; }
    #ui-datepicker-div .ui-datepicker-header > a.ui-datepicker-next::before {
      content: ""; }
  #ui-datepicker-div .ui-datepicker-calendar td {
    text-align: center; }
    #ui-datepicker-div .ui-datepicker-calendar td > a {
      height: 30px;
      width: 30px;
      line-height: 24px;
      display: inline-block;
      border-radius: 50%;
      text-align: center; }
      #ui-datepicker-div .ui-datepicker-calendar td > a:hover {
        background: #626366;
        color: #fff; }
    #ui-datepicker-div .ui-datepicker-calendar td.ui-datepicker-today > a {
      background-color: #bcbdbf;
      color: #fff; }
    #ui-datepicker-div .ui-datepicker-calendar td.ui-state-disabled {
      opacity: .5; }

.addressfield-container-inline {
  max-width: 600px;
  display: flex; }
  .addressfield-container-inline .form-item:first-child {
    width: 30%;
    min-width: 80px; }
  .addressfield-container-inline .form-item:last-child {
    width: 70%;
    margin-right: 0; }

.form-item {
  margin-top: 0; }
  .form-item label {
    display: block;
    font-weight: normal; }
    .form-item label.option {
      display: inline-block;
      font-weight: 400;
      vertical-align: middle; }
  .form-item .description {
    font-size: .85em;
    line-height: normal; }
    .form-item .description p {
      margin-bottom: .25em; }

.inline-fields label {
  display: inline-block;
  width: 30%; }

.marker,
.form-required {
  color: #d9534f; }

.auto_upload-processed + .form-submit {
  display: none !important; }

.tabledrag-handle {
  position: relative;
  z-index: 3;
  width: 41px; }
  .tabledrag-handle .handle {
    box-sizing: content-box; }

table.tabledrag-processed {
  width: 100%; }

tr.draggable > td {
  padding-top: 15px;
  border-bottom: 1px solid #e5e6e6; }
  tr.draggable > td:first-child {
    padding-left: 56px;
    position: relative; }
    tr.draggable > td:first-child .tabledrag-handle {
      margin-left: -56px; }
  tr.draggable > td.checkbox, tr.draggable > td:last-child {
    text-align: center; }

tr.draggable .form-item {
  border: 0 none;
  box-shadow: none;
  padding-bottom: 0; }

tr.draggable.drag td {
  opacity: .8;
  background-color: #fae3c4; }

tr.draggable.drag-previous td {
  background-color: #fae3c4; }

tr.draggable .tabledrag-changed {
  color: #fae3c4;
  display: block;
  width: 20px;
  background: #b06d0f;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 50% 0 0;
  height: 20px;
  text-align: center;
  line-height: 26px;
  vertical-align: middle; }

.image-widget {
  padding-left: 125px;
  position: relative; }
  .image-widget .filefield-sources-list {
    margin-left: -125px; }
  .image-widget .image-preview {
    float: left;
    width: 110px;
    margin-left: -125px; }
  .image-widget .form-file {
    margin-top: 7.5px;
    margin-left: -125px; }

.container-inline div,
.container-inline label {
  display: inline; }

.form-actions {
  padding-top: .5em; }
  .form-actions::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .form-actions input {
    margin-right: 5px; }

form.node-form #edit-submit,
.webform-client-form .form-submit {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #626366;
  background-color: #626366;
  color: #fff;
  padding: 15px 20px;
  border-radius: 0;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  form.node-form #edit-submit:hover,
  .webform-client-form .form-submit:hover {
    border-color: #6e7073;
    background-color: #6e7073;
    color: #fff; }
  form.node-form #edit-submit:active, form.node-form #edit-submit:focus,
  .webform-client-form .form-submit:active,
  .webform-client-form .form-submit:focus {
    border-color: #565659;
    background-color: #565659;
    color: #fff; }
  form.node-form #edit-submit:hover,
  .webform-client-form .form-submit:hover {
    text-decoration: none; }

form.node-form #edit-delete {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: #d9534f;
  border-color: #d9534f;
  text-shadow: 0 1px 1px #e48481;
  color: #fff;
  padding: 15px 20px;
  border-radius: 0;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  form.node-form #edit-delete:hover {
    background-color: #dc5f5c;
    border-color: #dc5f5c;
    color: #fff !important; }
  form.node-form #edit-delete:active, form.node-form #edit-delete:focus {
    background-color: #d64742;
    border-color: #d64742;
    color: #a02622; }
  form.node-form #edit-delete:hover {
    text-decoration: none; }

body.page-node-edit {
  padding-bottom: 30px; }

form.node-form .form-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e2e2e2;
  text-align: center;
  z-index: 99;
  padding: 5px 0 10px;
  margin: 0; }
  form.node-form .form-actions .form-submit {
    margin-right: 3px !important; }

#user-profile-form input[type="text"],
#user-profile-form .form-text,
#user-profile-form textarea,
#user-profile-form .form-textarea-wrapper textarea,
#user-profile-form select,
.form-item-mollom-captcha input[type="text"] {
  width: auto; }

.node-form .form-item,
.node-form .field-type-text-long-form,
.node-form .field-type-text-with-summary {
  border-bottom: 1px solid #d8d9da;
  padding-bottom: 25px;
  margin-bottom: 25px; }
  .node-form .form-item .form-item,
  .node-form .field-type-text-long-form .form-item,
  .node-form .field-type-text-with-summary .form-item {
    border: 0 none;
    box-shadow: none;
    padding-bottom: 0; }

.views-row .field-type-text-long-form,
.views-row .field-type-text-with-summary,
.field-type-text-with-summary .field-type-text-with-summary,
.field-type-text-long-form .form-item,
.field-type-text-with-summary .form-item,
.node-form .field-type-text-long-form .form-item,
.node-form .field-type-text-with-summary .form-item {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0 none;
  box-shadow: none; }

.form-item + .description,
.form-item .description {
  font-size: 12px;
  padding: .5em 0;
  color: #626366;
  line-height: 1.35;
  font-style: italic; }
  .form-item + .description::before,
  .form-item .description::before {
    font-family: "iconfont";
    font-weight: normal !important;
    font-style: normal !important;
    margin-right: 4px;
    content: ""; }

.form-item:hover + .description,
.form-item:hover > .description,
.form-item .description:hover {
  color: #626366; }

.fieldset-description {
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0;
  position: relative;
  color: #1f7e9a;
  background-color: #c5e9f3;
  border-color: #5bc0de; }
  .fieldset-description::before {
    font-family: "iconfont";
    position: absolute;
    left: 15px;
    top: 14px;
    font-size: 25px;
    line-height: normal; }
  .fieldset-description ul {
    margin-top: 0;
    margin-bottom: 0; }
  .fieldset-description a {
    color: #1f7e9a; }
  .fieldset-description::before {
    content: ""; }

.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 0; }

.form-checkboxes .form-item,
.form-radios .form-item {
  float: none;
  padding: .75em 0 3px 1%;
  margin: 0;
  line-height: normal; }

.form-checkboxes > .form-item,
.form-type-bef-checkbox {
  appearance: none; }
  .form-checkboxes > .form-item input[type='checkbox'],
  .form-type-bef-checkbox input[type='checkbox'] {
    width: auto;
    float: left;
    margin-right: .75em;
    background: transparent;
    border: none; }
    .form-checkboxes > .form-item input[type='checkbox']:checked, .form-checkboxes > .form-item input[type='checkbox']:not(:checked),
    .form-type-bef-checkbox input[type='checkbox']:checked,
    .form-type-bef-checkbox input[type='checkbox']:not(:checked) {
      background: transparent;
      position: relative;
      visibility: hidden;
      display: none;
      margin: 0;
      padding: 0; }
    .form-checkboxes > .form-item input[type='checkbox']:checked + label::before,
    .form-type-bef-checkbox input[type='checkbox']:checked + label::before {
      background: #fff;
      content: ""; }
    .form-checkboxes > .form-item input[type='checkbox']:invalid + label::before,
    .form-type-bef-checkbox input[type='checkbox']:invalid + label::before {
      border-color: #d9534f; }
    .form-checkboxes > .form-item input[type='checkbox'] + label,
    .form-type-bef-checkbox input[type='checkbox'] + label {
      cursor: pointer;
      vertical-align: top; }
      .form-checkboxes > .form-item input[type='checkbox'] + label::before,
      .form-type-bef-checkbox input[type='checkbox'] + label::before {
        content: ' ';
        display: inline-block;
        width: 24px;
        height: 24px;
        font-family: "iconfont";
        text-align: center;
        vertical-align: middle;
        line-height: 24px;
        position: relative;
        top: 0;
        border: 1px solid #d8d9da;
        background: #fff;
        margin-right: .35em; }
    .form-checkboxes > .form-item input[type='checkbox']:hover + label::before,
    .form-type-bef-checkbox input[type='checkbox']:hover + label::before {
      background: #fff;
      border-color: #636466;
      box-shadow: inset 0 0 0 2px #fff; }

.date-padding {
  display: flex; }
  .date-padding label {
    color: #d8d9da; }
  .date-padding .form-item {
    margin-right: 15px;
    margin-bottom: 0; }

.date-no-float:last-child {
  margin-bottom: 0; }

.form-media .preview:empty {
  display: none; }

.filter-guidelines {
  margin: 10px; }
  .filter-guidelines .tips {
    padding: 10px 0 0; }

.media-wrapper {
  z-index: 999999; }

.search-form {
  margin: 0; }
  .search-form .container-inline {
    display: table;
    display: flex; }
    .search-form .container-inline > * {
      display: table-cell; }
    .search-form .container-inline .form-actions {
      padding: 0;
      margin: 0; }
      .search-form .container-inline .form-actions .form-submit {
        margin: 0; }
  .search-form .form-type-searchfield {
    position: relative;
    margin-right: 2px; }
    .search-form .form-type-searchfield::before {
      font-family: "iconfont";
      font-weight: normal !important;
      font-style: normal !important;
      margin-right: 4px;
      content: "";
      color: #636466; }
    .search-form .form-type-searchfield::before {
      position: absolute;
      top: 50%;
      line-height: 1em;
      margin-top: -9px;
      left: 7px; }
  .search-form .form-text {
    padding-left: 27px; }
  .search-form:hover .form-item::before {
    color: #636466; }

#edit-advanced .fieldset-legend {
  margin-left: -10px; }
  #edit-advanced .fieldset-legend a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #626366;
    background-color: #626366;
    color: #fff;
    padding: 15px 20px;
    border-radius: 0;
    line-height: 18px;
    font-size: 16px;
    line-height: 1.05em; }
    #edit-advanced .fieldset-legend a:hover {
      border-color: #6e7073;
      background-color: #6e7073;
      color: #fff; }
    #edit-advanced .fieldset-legend a:active, #edit-advanced .fieldset-legend a:focus {
      border-color: #565659;
      background-color: #565659;
      color: #fff; }
    #edit-advanced .fieldset-legend a::before {
      line-height: 1;
      display: inline-block;
      font-family: "iconfont";
      font-style: normal;
      font-weight: normal;
      margin-right: 5px;
      text-align: center;
      content: ""; }
    #edit-advanced .fieldset-legend a:hover {
      text-decoration: none; }

.search-results {
  margin: 0;
  padding: 0; }
  .search-results .search-result {
    border-bottom: 1px solid #d8d9da; }
    .search-results .search-result .title {
      margin-top: 0;
      margin-bottom: .25em; }

#user-register-form fieldset, #edit-register fieldset {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  border-color: #e5e6e6;
  border-radius: 8px;
  background-color: #fff; }
  #user-register-form fieldset:before, #edit-register fieldset:before, #user-register-form fieldset:after, #edit-register fieldset:after {
    display: table;
    content: " "; }
  #user-register-form fieldset:after, #edit-register fieldset:after {
    clear: both; }

#user-register-form #edit-submit, #edit-register #edit-submit {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
  padding: 18.75px 25px;
  border-radius: 0;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  #user-register-form #edit-submit:hover, #edit-register #edit-submit:hover {
    background-color: #67bd67;
    border-color: #67bd67;
    color: #fff; }
  #user-register-form #edit-submit:active, #edit-register #edit-submit:active, #user-register-form #edit-submit:focus, #edit-register #edit-submit:focus {
    background-color: #51b351;
    border-color: #51b351;
    color: #357935; }
  #user-register-form #edit-submit:hover, #edit-register #edit-submit:hover {
    text-decoration: none; }

#edit-register {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  border-color: #e5e6e6;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 25px; }
  #edit-register:before, #edit-register:after {
    display: table;
    content: " "; }
  #edit-register:after {
    clear: both; }

.not-logged-in.page-user .page-title::before,
.not-logged-in.page-toboggan-denied .page-title::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px;
  content: ""; }

#edit-subscriptions {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  border-color: #e5e6e6;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 1em; }
  #edit-subscriptions:before, #edit-subscriptions:after {
    display: table;
    content: " "; }
  #edit-subscriptions:after {
    clear: both; }
  #edit-subscriptions .form-text {
    width: 100%; }
  #edit-subscriptions .fieldset-wrapper {
    padding-top: 0; }
  #edit-subscriptions #edit-newsletters {
    margin-bottom: 1em; }

#user-profile-form {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  border-color: #e5e6e6;
  border-radius: 8px;
  background-color: #fff; }
  #user-profile-form:before, #user-profile-form:after {
    display: table;
    content: " "; }
  #user-profile-form:after {
    clear: both; }

.pane-user-simplenews h3 {
  margin: 0;
  font-size: 1em; }

.pane-user-simplenews dl,
.pane-user-simplenews dd {
  margin: 0; }

.webform-confirmation {
  border-width: 1px;
  border-style: solid;
  padding: 25px 30px;
  border-color: #e5e6e6;
  border-radius: 8px;
  background-color: #fff; }
  .webform-confirmation:before, .webform-confirmation:after {
    display: table;
    content: " "; }
  .webform-confirmation:after {
    clear: both; }

.webform-client-form .form-submit {
  float: right; }

.webform-client-form fieldset {
  margin-bottom: 25px; }

.page-node-done .links {
  margin-top: 1em; }
  .page-node-done .links a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #626366;
    background-color: #626366;
    color: #fff;
    padding: 15px 20px;
    border-radius: 0;
    line-height: 18px;
    font-size: 16px;
    line-height: 1.05em; }
    .page-node-done .links a:hover {
      border-color: #6e7073;
      background-color: #6e7073;
      color: #fff; }
    .page-node-done .links a:active, .page-node-done .links a:focus {
      border-color: #565659;
      background-color: #565659;
      color: #fff; }
    .page-node-done .links a::before {
      line-height: 1;
      display: inline-block;
      font-family: "iconfont";
      font-style: normal;
      font-weight: normal;
      margin-right: 5px;
      text-align: center;
      content: ""; }
    .page-node-done .links a:hover {
      text-decoration: none; }

.webform-container-inline {
  display: flex; }
  .webform-container-inline > .form-item {
    margin-right: 5px; }
  .webform-container-inline .webform-calendar {
    align-self: center;
    height: 22px; }

.webform-component--rueckruf-gewuenscht-in-der-zeit-von-bis .form-checkboxes,
.webform-component--rueckruf-an-wochentagen .form-checkboxes {
  display: flex; }
  @media (max-width: 1169px) {
    .webform-component--rueckruf-gewuenscht-in-der-zeit-von-bis .form-checkboxes,
    .webform-component--rueckruf-an-wochentagen .form-checkboxes {
      flex-wrap: wrap; }
      .webform-component--rueckruf-gewuenscht-in-der-zeit-von-bis .form-checkboxes .form-item,
      .webform-component--rueckruf-an-wochentagen .form-checkboxes .form-item {
        margin-right: 30px; } }

.webform-component--angaben-zum-fahrzeug--ihre-preisvorstellung {
  position: relative; }
  .webform-component--angaben-zum-fahrzeug--ihre-preisvorstellung .field-suffix {
    position: absolute;
    right: 30px;
    bottom: 40px; }
  .webform-component--angaben-zum-fahrzeug--ihre-preisvorstellung .form-text {
    padding-right: 50px !important; }

.webform-component--ihre-nachricht legend,
.webform-component--kontakt legend {
  position: relative;
  padding-left: 40px; }
  .webform-component--ihre-nachricht legend::before,
  .webform-component--kontakt legend::before {
    position: absolute;
    left: 0;
    top: 0;
    font-family: "iconfont";
    line-height: 33px;
    width: 33px;
    height: 33px;
    background: #626366;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    border-radius: 100%;
    content: ""; }

@media (min-width: 992px) {
  .webform-component--ihre-nachricht {
    float: left;
    width: calc(50% - 15px);
    margin-right: 15px; } }

.webform-component--kontakt {
  content: ""; }
  @media (min-width: 992px) {
    .webform-component--kontakt {
      float: left;
      width: calc(50% - 15px);
      margin-left: 15px; } }
  .webform-component--kontakt legend::before {
    content: ""; }

.webform-component--datenschutz {
  clear: both; }

#form-destination input[type="text"] {
  padding: 5px;
  margin-bottom: 5px; }
  @media (min-width: 992px) {
    #form-destination input[type="text"] {
      width: 64%;
      margin-bottom: 0;
      margin-right: 1%;
      float: left; } }

#form-destination .form-submit {
  white-space: nowrap;
  height: auto;
  width: 100%; }
  @media (min-width: 992px) {
    #form-destination .form-submit {
      width: 35%;
      float: left; } }

@media (min-width: 992px) {
  #webform-client-form-906 > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem; }
    #webform-client-form-906 > div > * {
      width: auto !important;
      margin: 0 !important; }
    #webform-client-form-906 > div > .form-item,
    #webform-client-form-906 > div > .form-actions {
      grid-column: 1/-1; }
    #webform-client-form-906 > div .form-submit {
      margin: 0 !important; }
    #webform-client-form-906 > div .webform-component--ihre-nachricht > .fieldset-wrapper {
      height: 100%; }
    #webform-client-form-906 > div .webform-component-textarea {
      height: 100%;
      display: flex;
      flex-direction: column; }
      #webform-client-form-906 > div .webform-component-textarea > label {
        flex: 0 0 auto; }
      #webform-client-form-906 > div .webform-component-textarea > .form-textarea-wrapper {
        flex: 1 1 auto; }
      #webform-client-form-906 > div .webform-component-textarea .form-textarea {
        height: 100%; } }
