button.ui-multiselect{
  width:95% !important;
  position:relative;
  &.ui-state-active{
    border-color:$highlight;
  }
  .ui-icon{
    position:absolute;
    right:$input-default-h-space;
    top:50%;
  }
  .ui-icon-triangle-1-s{
    content:" ";
    margin-top:-3px;
    border-width:6px;
    border-style:solid;
    border-color:$text-color transparent transparent;
  }
}
.ui-multiselect-menu{
  width:auto;
  // display:none; prevents animation
  @include flyout;
  display:block !important;
  max-height:0;
  padding-top:0;
  padding-bottom:0;
  overflow:hidden;
  min-width:280px;
  transition-property: max-height, padding;
  transition-duration: 350ms;
  &[style*="top"]{
    // jquery set some values on first opening.. except display:block ... so we need some kinda hack.
    max-height:500px;
    padding-top:$thin-grid;
    padding-bottom:$thin-grid;
  }
  &[style*="none"]{
    max-height:0;
    padding-top:0;
    padding-bottom:0;
  }
}
.ui-multiselect-checkboxes{
  margin:10px 0 0;
  padding:0 2px 0 0;
  overflow:auto;
  label{
    cursor:pointer;
    display:block;
    line-height:1.1em;
    border-bottom:1px solid $grey-light;
    padding:8px 4px 8px 28px;
    &:hover{
      color:$highlight-text-color;
      border-color:$highlight;
      background-color:$highlight;
    }
    input{
      margin-left:-23px;
      float:left;
      margin-top:2px;
      outline:2px solid $grey-light;
      &[aria-selected="true"]{
        outline:2px solid $highlight-lighter;
      }
    }
  }
  li{
    &:last-child{
      label{
        border-bottom:0 none;
      }
    }
  }
}
// -- -- Filter
$multiselect-filter-height:40px;
.ui-multiselect-filter{
  margin-bottom:5px;
  height:$multiselect-filter-height;
  text-indent:-9000px; // remove the damn label
  input{
    width:100% !important;
    height:$multiselect-filter-height;
    padding:0 5px;
    font-size:$font-small;
    float:left;
  }
}
.ui-multiselect-header{
  .ui-helper-reset{
    list-style:none;
    padding:0;
    margin:0;
    display: flex;
    justify-content:space-between;
    a{
      @include btn(ghost, xs);
      font-size:$font-small;
    }
    .ui-multiselect-close{
      display:none;
    }
  }
}
